.sponsors {
  img {
    max-height: 100px;
  }
  .owl-item {
    height: 100px;
    display: flex;
    align-items: center;
  }
}
