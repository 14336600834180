@font-face {
  font-family: 'GothamProBlack';
  src: url('../fonts/GothamPro/GothamProBlack.eot');
  src: url('../fonts/GothamPro/GothamProBlack.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProBlack.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProBlack.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProBlackItalic';
  src: url('../fonts/GothamPro/GothamProBlackItalic.eot');
  src: url('../fonts/GothamPro/GothamProBlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProBlackItalic.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProBlackItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProBold';
  src: url('../fonts/GothamPro/GothamProBold.eot');
  src: url('../fonts/GothamPro/GothamProBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProBold.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProBoldItalic';
  src: url('../fonts/GothamPro/GothamProBoldItalic.eot');
  src: url('../fonts/GothamPro/GothamProBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProBoldItalic.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProBoldItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProItalic';
  src: url('../fonts/GothamPro/GothamProItalic.eot');
  src: url('../fonts/GothamPro/GothamProItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProItalic.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProLight';
  src: url('../fonts/GothamPro/GothamProLight.eot');
  src: url('../fonts/GothamPro/GothamProLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProLight.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProLightItalic';
  src: url('../fonts/GothamPro/GothamProLightItalic.eot');
  src: url('../fonts/GothamPro/GothamProLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProLightItalic.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProLightItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProMedium';
  src: url('../fonts/GothamPro/GothamProMedium.eot');
  src: url('../fonts/GothamPro/GothamProMedium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProMedium.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProMediumItalic';
  src: url('../fonts/GothamPro/GothamProMediumItalic.eot');
  src: url('../fonts/GothamPro/GothamProMediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProMediumItalic.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProMediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamProRegular';
  src: url('../fonts/GothamPro/GothamProRegular.eot');
  src: url('../fonts/GothamPro/GothamProRegular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/GothamPro/GothamProRegular.ttf') format('truetype'),
  url('../fonts/GothamPro/GothamProRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
