
@mixin sectionHeader() {
  margin-bottom: 3.7rem;
  h2 {
    font-family: $body-font;
    font-size: 37px;
    line-height: 0;
    letter-spacing: 0;
    padding-left: 5px;

    small {
      font-family: $lato-black;
      font-size: 55px;
      color: #dfe0f6;
      display: block;
      line-height: 0.6;
      letter-spacing: 1px;
      padding-left: 15px;
      text-transform: uppercase;
    }
  }
  @media (max-width: 992px) {
    h2{
      font-size: 27px;
      small {
        font-size: 40px;
      }
    }
  }
  @media (max-width: 576px) {
    h2{
      small {
        font-size: 35px;
      }
    }
  }
}
@mixin aboutUsContent() {
  line-height: 1.6;
}
.about-us-content {
  @include aboutUsContent;
}
.about-us {
  img {
    height: 260px;
    width: 100%;
    object-fit: cover;

  }
  .excellance-text {
    .icon {
      img {
        height: auto;
        width: 50px;
      }
    }
  }
}
