@font-face {
  font-family: 'Lato-Regular';
  src: url('../fonts/Lato/Lato-Regular.eot');
  src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Regular.svg#Lato-Regular') format('svg'),
  url('../fonts/Lato/Lato-Regular.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Regular.woff') format('woff'),
  url('../fonts/Lato/Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-LightItalic';
  src: url('../fonts/Lato/Lato-LightItalic.eot');
  src: url('../fonts/Lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-LightItalic.svg#Lato-LightItalic') format('svg'),
  url('../fonts/Lato/Lato-LightItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-LightItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-LightItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato/Lato-Light.eot');
  src: url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Light.svg#Lato-Light') format('svg'),
  url('../fonts/Lato/Lato-Light.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Light.woff') format('woff'),
  url('../fonts/Lato/Lato-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Italic';
  src: url('../fonts/Lato/Lato-Italic.eot');
  src: url('../fonts/Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Italic.svg#Lato-Italic') format('svg'),
  url('../fonts/Lato/Lato-Italic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Italic.woff') format('woff'),
  url('../fonts/Lato/Lato-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-HairlineItalic';
  src: url('../fonts/Lato/Lato-HairlineItalic.eot');
  src: url('../fonts/Lato/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-HairlineItalic.svg#Lato-HairlineItalic') format('svg'),
  url('../fonts/Lato/Lato-HairlineItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-HairlineItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-HairlineItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Hairline';
  src: url('../fonts/Lato/Lato-Hairline.eot');
  src: url('../fonts/Lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Hairline.svg#Lato-Hairline') format('svg'),
  url('../fonts/Lato/Lato-Hairline.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Hairline.woff') format('woff'),
  url('../fonts/Lato/Lato-Hairline.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-BoldItalic';
  src: url('../fonts/Lato/Lato-BoldItalic.eot');
  src: url('../fonts/Lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg'),
  url('../fonts/Lato/Lato-BoldItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-BoldItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-BoldItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato/Lato-Bold.eot');
  src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Bold.svg#Lato-Bold') format('svg'),
  url('../fonts/Lato/Lato-Bold.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Bold.woff') format('woff'),
  url('../fonts/Lato/Lato-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-BlackItalic';
  src: url('../fonts/Lato/Lato-BlackItalic.eot');
  src: url('../fonts/Lato/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-BlackItalic.svg#Lato-BlackItalic') format('svg'),
  url('../fonts/Lato/Lato-BlackItalic.ttf') format('truetype'),
  url('../fonts/Lato/Lato-BlackItalic.woff') format('woff'),
  url('../fonts/Lato/Lato-BlackItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato-Black';
  src: url('../fonts/Lato/Lato-Black.eot');
  src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato/Lato-Black.svg#Lato-Black') format('svg'),
  url('../fonts/Lato/Lato-Black.ttf') format('truetype'),
  url('../fonts/Lato/Lato-Black.woff') format('woff'),
  url('../fonts/Lato/Lato-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
