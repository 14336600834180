.lock-screen{
    @include position(absolute, 50%, 0, null,0, 3);
    width: 300px;
    margin: 0 auto;
    @include transform(translateY(-50%));
    text-align: center;
    .form-control{
        height: calc(2.50rem + 2px);
        padding: 7px 15px;
        @include border(null, 1px, solid, $border-color);
        background: none;
        &:focus{
            border-color: $theme-color-3 !important;
            & ~ *{
                .input-group-text{
                    border-color: $theme-color-3;
                    border-left: none;
                }
            }
        }
    }
    &.bg-screen{
        color: $black;
        .form-control{
            color: $black;
            border-color: $black;
        }
    }
}